import React from 'react';
import { ExtendCSS } from 'vcc-ui';
import DeprecatedSectionContained from '@vcc-www/page-section/DEPRECATED_BROKEN_SectionContained';
import {
  maxBackgroundWidth12Columns,
  maxContentWidth12Columns,
} from '@vcc-www/constants/layout';
import EditorialProps from './IconCallouts.props';
import IconCallouts from './IconCallouts';

export const LARGE = 'large';
export const LARGE_TEXT = 'large-text';
export const LARGE_FROM_M = 'large-from-m';
export const REGULAR = 'regular';

export const IconCalloutsEditorial: React.FC<
  React.PropsWithChildren<EditorialProps>
> = ({
  background,
  topInnerSpacing,
  topOuterSpacing,
  bottomInnerSpacing,
  bottomOuterSpacing,
  gridLayoutWrapperSize,
  ...params
}) => {
  return (
    <DeprecatedSectionContained
      {...params}
      extend={sectionCSS(params.marginsOnMobile, gridLayoutWrapperSize)}
      data-autoid="IconCallouts"
    >
      <IconCallouts {...params} />
    </DeprecatedSectionContained>
  );
};

export default IconCallouts;

const sectionCSS =
  (
    marginsOnMobile?: boolean,
    gridLayoutWrapperSize?: EditorialProps['gridLayoutWrapperSize'],
  ): ExtendCSS =>
  ({ theme: { baselineGrid } }) => ({
    maxWidth:
      gridLayoutWrapperSize === '10'
        ? maxContentWidth12Columns
        : maxBackgroundWidth12Columns,
    untilM: {
      paddingRight: `${!marginsOnMobile ? baselineGrid * 3 : 0}px`,
      paddingLeft: `${!marginsOnMobile ? baselineGrid * 3 : 0}px`,
    },
  });
