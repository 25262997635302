import React from 'react';
import { ExtendCSS } from 'vcc-ui';
import DeprecatedSectionContained from '@vcc-www/page-section/DEPRECATED_BROKEN_SectionContained';
import {
  maxBackgroundWidth12Columns,
  maxContentWidth12Columns,
} from '@vcc-www/constants/layout';
import EditorialProps from './IconCallouts.props';
import IconCalloutsBase from '@vcc-www/icon-callouts';

type Props = EditorialProps;

const IconCallouts: React.FC<Props> = ({
  background,
  topInnerSpacing,
  topOuterSpacing,
  bottomInnerSpacing,
  bottomOuterSpacing,
  gridLayoutWrapperSize,
  ...params
}) => (
  <DeprecatedSectionContained
    {...{
      background,
      topInnerSpacing,
      topOuterSpacing,
      bottomInnerSpacing,
      bottomOuterSpacing,
    }}
    extend={sectionCSS(params.marginsOnMobile, gridLayoutWrapperSize)}
    data-autoid="IconCallouts"
  >
    <IconCalloutsBase {...params} />
  </DeprecatedSectionContained>
);

export default IconCallouts;

const sectionCSS =
  (
    marginsOnMobile?: boolean,
    gridLayoutWrapperSize?: EditorialProps['gridLayoutWrapperSize'],
  ): ExtendCSS =>
  ({ theme: { baselineGrid } }) => ({
    maxWidth:
      gridLayoutWrapperSize === '10'
        ? maxContentWidth12Columns
        : maxBackgroundWidth12Columns,
    untilM: {
      paddingRight: !marginsOnMobile ? baselineGrid * 3 : 0,
      paddingLeft: !marginsOnMobile ? baselineGrid * 3 : 0,
    },
  });
