import React from 'react';
import { Block, ExtendCSS, Flex, Inline, Text } from 'vcc-ui';
import { Button, Link } from '@vcc-www/buttons';
import IconTextList from '@vcc-www/icon-text-list';
import Disclaimer from '@vcc-www/disclaimer';
import {
  maxContentWidth10Columns,
  maxContentWidth12Columns,
} from '@vcc-www/constants/layout';
import { IconCalloutsProps } from './IconCallouts.props';

export const LARGE = 'large';
export const LARGE_TEXT = 'large-text';
export const LARGE_FROM_M = 'large-from-m';
export const REGULAR = 'regular';

const stripBaseUrl = (url?: string) =>
  (url || '').replace(/^https?:\/\/[^/]+volvocars\.com/, '');

const LARGE_ICON_SIZE = 80;

const IconCallouts: React.FC<React.PropsWithChildren<IconCalloutsProps>> = ({
  content,
  headingAlignment,
  iconLayout,
  downloadCta = false,
  smallIntroAlignment = 'left',
  gridLayoutSize = '12',
  showAll = false,
  variant,
}) => {
  const { title, intro, iconTexts, cta, smallIntroText, disclaimer } = content;

  const isLarge = variant === LARGE;
  const isLargeText = isLarge || variant === LARGE_TEXT;
  const isLargeFromM = variant === LARGE_FROM_M;

  const titleVariant =
    isLarge || isLargeText || isLargeFromM ? 'hillary' : 'columbus';
  const hasHeading = !!title || !!intro;

  const isCentered = iconLayout === 'center' && showAll;

  return (
    <Block
      extend={containerCSS(isLarge, isLargeFromM, gridLayoutSize, isCentered)}
    >
      {hasHeading && (
        <Text
          as="h2"
          variant="ootah"
          subStyle="emphasis"
          extend={titleCSS(
            isLarge,
            isLargeFromM,
            !!smallIntroText,
            headingAlignment,
          )}
        >
          {title}
          {!!intro && <Inline extend={introCSS}> {intro}</Inline>}
        </Text>
      )}
      {!!smallIntroText && (
        <Text
          variant="hillary"
          extend={smallIntroCSS(smallIntroAlignment)}
          data-testid="iconCallouts:smallIntro"
        >
          {smallIntroText}
        </Text>
      )}
      <IconTextList
        textVariant="columbus"
        titleVariant={titleVariant}
        {...(isLarge && { variant: 'center', iconHeight: LARGE_ICON_SIZE })}
        {...(isLargeFromM && {
          variant: 'center-from-m',
          iconHeight: LARGE_ICON_SIZE,
        })}
        {...(iconLayout && { variant: iconLayout })}
        content={{ iconTexts }}
        downloadCta={downloadCta}
        showAll={showAll}
      />
      {cta?.href && cta?.text && (
        <Flex extend={linkCSS(isLarge)}>
          {isLarge ? (
            <Button
              href={cta.href}
              target={cta.target}
              trackEventLabel={`${cta.text ?? ''} | ${stripBaseUrl(cta.href)}`}
              variant="outline"
              data-testid="iconCallouts:cta"
              data-autoid="iconCallouts:cta"
            >
              {cta.text}
            </Button>
          ) : (
            <Link
              href={cta.href}
              trackEventLabel={`${cta.text ?? ''} | ${stripBaseUrl(cta.href)}`}
              arrow="right"
              data-testid="iconCallouts:cta"
              data-autoid="iconCallouts:cta"
            >
              {cta.text}
            </Link>
          )}
        </Flex>
      )}
      <Disclaimer extend={disclaimerCSS} text={disclaimer} />
    </Block>
  );
};

export default IconCallouts;

const containerCSS = (
  isLarge: boolean,
  isLargeFromM: boolean,
  gridLayoutSize?: IconCalloutsProps['gridLayoutSize'],
  isCentered?: boolean,
): ExtendCSS => ({
  maxWidth:
    gridLayoutSize === '10'
      ? maxContentWidth10Columns
      : isCentered
        ? maxContentWidth12Columns / 2
        : maxContentWidth12Columns,
  marginLeft: 'auto',
  marginRight: 'auto',
  textAlign: isLarge ? 'center' : 'left',
  extend: {
    condition: isLargeFromM,
    style: {
      fromM: { textAlign: 'center' },
    },
  },
});

const titleCSS =
  (
    isLarge: boolean,
    isLargeFromM: boolean,
    smallIntroText?: boolean,
    headingAlignment?: 'left' | 'center' | 'right',
  ): ExtendCSS =>
  ({ theme: { baselineGrid } }) => {
    const notLeftAligned = headingAlignment && headingAlignment !== 'left';
    return {
      maxWidth: 600,
      ...(isLarge && {
        display: 'inline-block',
        textAlign: 'center',
      }),
      ...(headingAlignment && {
        textAlign: headingAlignment,
      }),
      ...(notLeftAligned && { maxWidth: '100%' }),
      ...(isLargeFromM && {
        fromM: {
          display: 'inline-block',
          textAlign: 'center',
        },
      }),
      margin: `0 0 ${
        smallIntroText ? baselineGrid * 2 : baselineGrid * (isLarge ? 10 : 5)
      }px`,
    };
  };

const introCSS: ExtendCSS = ({ theme: { color } }) => ({
  color: color.foreground.secondary,
});

const linkCSS =
  (isLarge: boolean): ExtendCSS =>
  ({ theme: { baselineGrid } }) => ({
    display: 'inline-block',
    marginTop: 5 * baselineGrid,
    alignItems: isLarge ? 'center' : 'flex-start',
    ...(!isLarge && {
      width: '100%',
      textAlign: 'center',
      marginTop: 3 * baselineGrid,

      untilL: { marginTop: 2 * baselineGrid },
    }),
  });

const smallIntroCSS =
  (smallIntroAlignment?: IconCalloutsProps['smallIntroAlignment']): ExtendCSS =>
  ({ theme: { color, baselineGrid } }) => ({
    color: color.foreground.secondary,
    marginBottom: 10 * baselineGrid,
    ...(smallIntroAlignment === 'center' && {
      textAlign: 'center',
      margin: 'auto',
      maxWidth: maxContentWidth12Columns / 2,
    }),
  });

const disclaimerCSS: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  marginTop: 2 * baselineGrid,
});
